<template>
    <div
        :id="_comId"
        v-if="closeFlag"
        @mousedown="$emit('mousedown')"
        :class="`${__class} animated${operateClass}`"
        v-show="__show"
        :style="{
            'z-index': curIndex,
            height: height,
            width: width,
            [`border-${border}`]: '1px dashed #e4e4e4'
        }"
    >
        <div class="top" v-if="!!title">
            <table>
                <tr>
                    <td>
                        <div class="title">
                            <div class="big" v-html="title"></div>
                            <small
                                :class="`small${isChrome ? ' small-font' : ''}`"
                                v-if="!!subtitle"
                                >{{ subtitle }}</small
                            >
                        </div>
                    </td>
                    <td align="right">
                        <div class="tools">
                            <jgp-tools
                                _classname="panel-tools"
                                v-if="getAllTools"
                            >
                                <a
                                    href="javascript:void(0)"
                                    :class="panelBarStyle"
                                    :key="tIndex"
                                    v-for="(tool, tIndex) in getAllTools"
                                    @click.stop="doFn(tool)"
                                >
                                    <i
                                        :class="`fa fa-${tool.icon}`"
                                        v-tooltip.bottom-end="{
                                            container: tipContainer,
                                            content: `${tool.text}`,
                                            autoHide: false
                                        }"
                                    ></i>
                                </a>
                            </jgp-tools>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <transition
            @after-enter="afterEnter"
            @after-leave="afterLeave"
            :leave-active-class="`animated fadeOut${direction}`"
            :enter-active-class="`animated fadeIn${
                direction == 'Up' ? 'Down' : 'Left'
            }`"
        >
            <div
                :class="`content${!title ? ' full' : ''}${
                    url ? ' has-iframe' : ''
                }`"
                v-show="collapse"
                ref="scroll-wrap"
            >
                <div
                    class="html-inset"
                    v-show="!getUrl"
                    :style="{ padding: padding }"
                >
                    <!-- @slot 内容 -->
                    <slot></slot>
                </div>
                <iframe
                    v-show="getUrl"
                    :name="iframeName"
                    :id="iframeName"
                    @load="frameLoaded"
                    :src="getUrl"
                    allow="fullscreen"
                    webkitallowfullscreen="true"
                    ref="iframe"
                ></iframe>
            </div>
        </transition>
    </div>
</template>

<script>
import Check from 'check-types'
import Scrollbar from 'smooth-scrollbar'
import Common from '../../utils/common'
/**
 * 面板组件
 * @author 娄飞 【Gavin Lou】
 * @displayName JgpPanel
 */
export default {
    toggleState: {
        zindex: 0
    },
    data() {
        return {
            closeFlag: true,
            operate: '',
            collapse: true,
            contentFull: false,
            direction: 'up',
            panelBarStyle: 'button button-default button-circle button-tiny',
            urlRandom: '',
            defTools: {
                hide: {
                    text: '隐藏',
                    icon: 'window-minimize',
                    fn: this.hidePanel
                },
                max: {
                    text: '最大化',
                    icon: 'expand',
                    fn: this.toggleMax
                },
                refresh: {
                    text: '刷新',
                    icon: 'refresh',
                    fn: this.refresh
                },
                close: {
                    text: '关闭',
                    icon: 'dot-circle-o',
                    fn: this.close
                }
            },
            scrollBar: undefined,
            isFrameLoaded: false
        }
    },
    props: {
        /**
         * 标题
         */
        _title: String,
        /**
         * 副标题
         */
        _subtitle: String,
        /**
         * 地址
         */
        _url: String,
        /**
         * 默认工具，<pre>默认:["hide", "max", "refresh", "close"]</pre>
         */
        _default_tools: {
            type: [String, Array],
            default() {
                return ['hide', 'max', 'refresh', 'close']
            }
        },
        /**
         * 自定义工具
         */
        _tools: [String, Array],
        /**
         * @ignore
         */
        _zindex: Number,
        /**
         * 内边距
         */
        _padding: {
            type: String,
            default: '0px'
        },
        /**
         * 边框位置 上、下、左、右
         */
        _border: String,
        /**
         * @ignore
         */
        _force_scroll: {
            type: String,
            default: 'false'
        },
        /**
         * 内容加载完毕回调
         */
        _onload: [String, Function]
    },
    computed: {
        getUrl() {
            if (this.url) {
                return Common.BASE + this.url + this.urlRandom
            } else {
                return undefined
            }
        },
        tipContainer() {
            if (this.$parent.cType === 'jgp-dialog') {
                return '#' + this.$parent._comId + ' .jgp-tools'
            } else {
                return '#' + this._comId + ' .jgp-tools'
            }
        },
        getDefaultTools() {
            return Common.toJson(this.default_tools)
        },
        getTools() {
            if (
                this.tools &&
                Check.string(this.tools) &&
                Common.trim(this.tools) !== ''
            ) {
                return Common.toJson(this.tools)
            } else {
                return this.tools
            }
        },
        getAllTools() {
            let tools = []
            if (this.getDefaultTools && this.getDefaultTools.length > 0) {
                this.getDefaultTools.forEach((key) => {
                    tools.push(this.defTools[key])
                })
            }
            if (this.getTools) {
                tools = tools.concat(this.getTools)
            }
            return tools
        },
        getForceScroll() {
            return this.force_scroll
        },
        operateClass() {
            return this.operate
        },
        iframeName() {
            if (this.url) {
                return this.cType + '-iframe-' + Common.rndNum(6)
            }
        },
        curIndex() {
            return this.zindex || 1
        },
        isChrome() {
            return Common.userAgent().indexOf('Chrome') !== -1
        }
    },
    watch: {
        draggable(value) {
            this.$forceUpdate()
        }
    },
    methods: {
        onScroll(e, position) {
            this.position = position
        },
        afterEnter() {
            // this.$emit("animate-over", { type: "enter" });
        },
        afterLeave() {
            // this.$emit("animate-over", { type: "leave" });
        },
        toggleMax() {
            if (this.operate.includes(' max')) {
                this.operate = this.operate.replace(' zoomIn max', '')
                this.zindex = this.$options.toggleState.zindex
                this.$set(this.defTools.max, 'text', '最大化')
            } else {
                this.operate += ' zoomIn max'
                this.$options.toggleState = Object.assgin(
                    {},
                    this.$options.toggleState,
                    {
                        zindex: this.zindex
                    }
                )
                this.$set(this.defTools.max, 'text', '还原')
                this.zindex = Common.zIndex()
            }
        },
        resize() {
            if (!this.title) {
                // this.content
            }
        },
        refresh() {
            if (this.url) {
                this.isFrameLoaded = false
                if (this.url.indexOf('?') !== -1) {
                    this.urlRandom = '&r=' + Common.rndNum(6)
                } else {
                    this.urlRandom = '?r=' + Common.rndNum(6)
                }
            } else {
                this.$forceUpdate()
            }
        },
        close() {
            this.closeFlag = false
        },
        doFn(tool) {
            Common.doFn(tool.fn, window)
        },
        load(url) {
            if (this.url) {
                this.$set(this, 'url', url)
            }
        },
        async checkLoaded() {
            let times = 0
            const _this = this
            return new Promise((resolve, reject) => {
                const timer = setInterval(() => {
                    if (_this.isFrameLoaded) {
                        resolve()
                        console.log('resolve')
                        clearInterval(timer)
                    }
                    if (times++ > 300) {
                        reject(new Error('3s超时了'))
                        console.log('reject')
                        clearInterval(timer)
                    }
                }, 10)
            })
        },
        setTitle(title) {
            this.title = title
        },
        frameLoaded() {
            if (this.onload) {
                Common.doFn(this.onload, {
                    el: this.$refs.iframe || this.$el
                })
            }
            Common.loading(false, true)
            if (this.origin && this.$refs.iframe) {
                this.$refs.iframe.contentWindow['parentContext'] = this.origin
            }
            this.isFrameLoaded = true
        },
        contentWindow() {
            if (this.$refs.iframe) {
                return this.$refs.iframe.contentWindow
            }
        },
        hidePanel(callback) {
            this.direction = 'Up'
            this.collapse = !this.collapse
            if (callback) callback(this.collapse)
        },
        scrollIntoView(el) {
            this.scrollBar.scrollIntoView(el)
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
        Common.loading(true, true)
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {},
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {},
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        this.$nextTick(() => {
            if (!this.url) {
                let scroll = this.$refs['scroll-wrap']
                this.scrollBar = Scrollbar.init(scroll, {
                    alwaysShowTracks: true,
                    continuousScrolling: this.getForceScroll
                })
                Common.loading(false, true)
            }

            if (this.origin && this.$refs.iframe) {
                this.$refs.iframe.contentWindow['parentContext'] = this.origin
                this.$refs.iframe.contentWindow['currentDialog'] =
                    this.$parent.$parent
            }
        })
    },
    /*
     数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
     比如手动移除已添加的事件监听器。
     */
    beforeUpdate() {},
    /*
     由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

     当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
     然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
     用计算属性或 watcher 取而代之。

     注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
     绘完毕，可以用 vm.$ nextTick 替换掉 updated：
     */
    updated() {},
    /* keep-alive 组件激活时调用。 */
    activated() {},
    /* keep-alive 组件停用时调用。 */
    deactivated() {},
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {},
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {}
}
</script>
